.btn {
    display: inline-block;
    cursor: pointer;
    outline: none !important;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    font-family: inherit;
    padding: 1.4rem;
    border: none;
    box-shadow: 0 1px 5px var(--primary-color-dark);
    background-color: var(--primary-color-very-light);
    color: var(--nuetral-dark);
    border-radius: 50%;
    font-size: 1.6rem;
    transition: color 0.3s , background-color 0.3s;
}

/*to get rid of extra space under inline-block / inline elements*/
.btn i {
    float: left;
}

.btn--rect{
    text-decoration: underline;
    text-decoration-color: var(--primary-color);
}

.btn:active {
    color: var(--nuetral-light);
    background-color: var(--nuetral-dark);
}

@media (hover: hover) and (pointer: fine){
    .btn:hover{
        color: var(--nuetral-light);
        background-color: var(--nuetral-dark);
    }
}
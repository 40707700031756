html {
  --primary-color: #3680fa;
  --primary-color-light: #c4d8fa;
  --primary-color-very-light: #dce6f5;
  --primary-color-dark: #2666cf;
  --primary-color-very-dark: #174ea8;
  --nuetral-dark: #343434;
  --nuetral-dark-light: #525151;
  --nuetral-light: #fff;
  --nuetral-light-transparent: #fffa;
  font-size: 62.5%;
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: var(--nuetral-dark);
  background-color: var(--nuetral-light);
  min-height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
}

a {
  text-decoration: none;
}

.page {
  background-color: var(--primary-color-very-light);
  width: 100%;
  min-height: 100vh;
}

@media only screen and (width >= 62em) {
  body {
    font-size: 1.6rem;
  }

  .page {
    box-shadow: 0 0 10px var(--primary-color-dark);
    width: 90%;
    max-width: 140rem;
    margin: 0 auto;
  }
}

.time {
  --time-height: 3rem;
  --time-width: 2rem;
  background-image: linear-gradient(180deg, var(--nuetral-dark-light), var(--nuetral-dark));
  background-color: var(--nuetral-dark);
  color: var(--nuetral-light);
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 1rem .25rem;
  font-size: 1.5rem;
  display: flex;
}

.time__number {
  display: flex;
  position: relative;
}

.time > .time__number + .time__number {
  margin-right: 1.5rem;
}

.time__two, .time__one {
  background-image: linear-gradient(180deg, var(--primary-color) 0%, var(--primary-color) 50%, var(--primary-color-very-dark) 50%, var(--primary-color-very-dark) 100%);
  background-color: var(--primary-color-dark);
  width: var(--time-width);
  height: var(--time-height);
  border-radius: 20%;
  margin-right: .4rem;
  position: relative;
  overflow: hidden;
}

.time__next, .time__current {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.time__current {
  line-height: var(--time-height);
  transition: all;
  transform: translateY(0);
}

.time__next {
  transform: translateY(calc(-1 * var(--time-height)));
  transition: all;
}

.time__current--anim {
  transform: translateY(var(--time-height));
  transition: transform .4s;
}

.time__next--anim {
  line-height: var(--time-height);
  transition: transform .4s;
  transform: translateY(0);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  border: none;
  width: 100%;
}

thead tr {
  background-image: linear-gradient(180deg, var(--primary-color), var(--primary-color-very-dark));
  background-color: var(--primary-color-dark);
  border-bottom: 1px solid var(--nuetral-dark);
}

th {
  color: var(--nuetral-light);
  padding: 2rem .5rem;
  font-size: 0;
}

th i {
  font-size: 2rem;
}

td {
  padding: 2rem .5rem;
}

tbody tr:nth-child(odd) {
  background-image: linear-gradient(180deg, var(--primary-color-very-light), var(--primary-color-light));
  background-color: var(--primary-color-light);
  border-bottom: 1px solid var(--nuetral-light);
}

tbody tr:nth-child(2n) {
  border-bottom: 1px solid var(--nuetral-light);
  background-color: var(--primary-color-very-light);
}

.configs__app-v6 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.configs__info {
  --info-font-size: 2.1rem;
  cursor: pointer;
  margin-left: auto;
  position: relative;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: #0000 !important;
  outline: none !important;
}

.configs__app-name-wrapper {
  flex: 1 0 0;
}

.configs__app-name {
  color: #000;
  border-bottom: 1px solid var(--primary-color);
  padding-bottom: .2rem;
  transition: color .3s;
}

.configs__app-name-wrapper .configs__app-name {
  border-bottom: none;
}

.configs__app-icon {
  font-size: var(--info-font-size);
}

.tooltip, .tooltip:after {
  --tooltip-scale: 0;
  opacity: 0;
  z-index: 10;
  transition: all .3s;
  position: absolute;
}

.tooltip {
  transform: translate(-100%, -100%) scale(var(--tooltip-scale));
  transform-origin: 100% 100%;
  background-color: var(--nuetral-dark);
  color: #fff;
  border-radius: 10px;
  width: max-content;
  max-width: 66.6667vw;
  padding: 1em;
  font-family: iranyekan, "sans-serif";
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  top: -1rem;
  left: 150%;
}

.tooltip:after {
  content: "";
  top: 100%;
  right: calc(var(--info-font-size) / 2);
  border: 1rem solid #0000;
  border-top-color: var(--nuetral-dark);
  transform: translateX(-.08rem) scale(var(--tooltip-scale));
  transform-origin: 100% 0;
}

.configs__app-radio {
  display: none;
}

.configs__app-radio:checked + .configs__app-icon + .tooltip, .configs__app-radio:checked + .configs__app-icon + .tooltip:after {
  opacity: 1;
  --tooltip-scale: 1;
}

.tooltip__link {
  color: var(--primary-color-light);
  font-family: Arial, Helvetica, sans-serif;
}

.configs__os {
  font-size: 0;
}

.configs__os-container {
  margin: -.5rem;
  font-size: 0;
}

.configs__os i {
  margin: .25rem;
  font-size: 2.5rem;
}

@media only screen and (width >= 37em) {
  .configs__os i {
    margin: .5rem;
    font-size: 3.5rem;
  }
}

@media only screen and (width >= 48em) {
  .tooltip {
    max-width: 50vw;
  }
}

@media only screen and (width >= 62em) {
  td, th {
    padding: 2.5rem;
  }

  th i {
    font-size: 2.7rem;
  }

  .tooltip {
    max-width: 40vw;
  }
}

@media (hover: hover) and (pointer: fine) {
  .configs__app-name:hover {
    color: var(--primary-color-very-dark);
  }

  .configs__app-name-wrapper .configs__app-name:hover {
    color: #000;
  }
}

.btn {
  cursor: pointer;
  box-shadow: 0 1px 5px var(--primary-color-dark);
  background-color: var(--primary-color-very-light);
  color: var(--nuetral-dark);
  border: none;
  border-radius: 50%;
  padding: 1.4rem;
  font-family: inherit;
  font-size: 1.6rem;
  transition: color .3s, background-color .3s;
  display: inline-block;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: #0000 !important;
  outline: none !important;
}

.btn i {
  float: left;
}

.btn--rect {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--primary-color);
  text-decoration-color: var(--primary-color);
}

.btn:active {
  color: var(--nuetral-light);
  background-color: var(--nuetral-dark);
}

@media (hover: hover) and (pointer: fine) {
  .btn:hover {
    color: var(--nuetral-light);
    background-color: var(--nuetral-dark);
  }
}

@font-face {
  font-family: icomoon;
  src: url("icomoon.084e7ee1.woff") format("woff"), url("icomoon.71c308d4.ttf") format("truetype"), url("icomoon.00feb274.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon-mac:before {
  content: "";
}

.icon-android:before {
  content: "";
}

.icon-os:before {
  content: "";
}

.icon-ssh:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-windows:before {
  content: "";
}

.icon-ios:before {
  content: "";
}

.icon-linux:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-eye:before {
  content: "";
}

.icon-eye-blocked:before {
  content: "";
}

@font-face {
  font-family: iranyekan;
  src: url("iranyekanwebregularfanum.ee2218cf.woff2") format("woff2"), url("iranyekanwebregularfanum.c62c7245.woff") format("woff"), url("iranyekanwebregularfanum.78f4c2db.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.loading-page img {
  width: 13rem;
}

.login {
  background-color: var(--primary-color-very-light);
  box-shadow: 0 1px 5px var(--primary-color-dark);
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 37.5rem;
  margin: 0 auto;
  padding: 4rem;
  display: flex;
}

.login > * + * {
  margin-top: 2rem;
}

.login__photo {
  box-shadow: 0 1px 2px var(--primary-color-dark);
  border-radius: 50%;
  max-width: 30%;
}

.login__input {
  background-color: var(--nuetral-light);
  border-radius: 20px;
  width: 75%;
  position: relative;
}

.login__eye {
  cursor: pointer;
  font-size: 1.4rem;
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);
}

.login__password, .login__btn {
  box-shadow: 0 1px 2px var(--primary-color-dark);
  border: 0;
  border-radius: 20px;
  height: 4rem;
  font-family: iranyekan, "sans-serif";
  font-size: 1.2rem;
  font-weight: 400;
  outline: none !important;
}

.login__password {
  background-color: #0000;
  width: 100%;
  padding-right: 1.5rem;
}

.login__btn {
  background-color: var(--primary-color);
  color: var(--nuetral-light);
  cursor: pointer;
  -webkit-tap-highlight-color: #0000;
  justify-content: center;
  align-items: center;
  width: 75%;
  transition: background-color .3s;
  display: flex;
}

.login__btn:active {
  background-color: var(--primary-color-very-dark);
}

.login__btn:disabled {
  background-color: var(--primary-color);
  cursor: wait;
}

.login__btn-loading {
  width: 2rem;
}

.login__btn-loading--hidden, .login__btn-text--hidden {
  display: none;
}

.login__wrong {
  color: red;
  font-family: iranyekan, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
}

.login__wrong--hidden {
  visibility: hidden;
}

@media (hover: hover) and (pointer: fine) {
  .login__btn:hover {
    background-color: var(--primary-color-very-dark);
  }
}



/*# sourceMappingURL=index.2c221cad.css.map */

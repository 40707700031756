.time {
    --time-height: 3rem;
    --time-width: 2rem;
    background-image: linear-gradient(180deg, var(--nuetral-dark-light), var(--nuetral-dark));
    background-color: var(--nuetral-dark);
    color: var(--nuetral-light);
    font-size: 1.5rem;
    padding: 1rem 0.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.time__number {
    position: relative;
    display: flex;
}

.time > .time__number + .time__number {
    margin-right: 1.5rem;
}

.time__two,
.time__one{
    overflow: hidden;
    background-image: linear-gradient(180deg,var(--primary-color) 0%, var(--primary-color) 50%,
    var(--primary-color-very-dark) 50%, var(--primary-color-very-dark) 100%);
    background-color: var(--primary-color-dark);
    width: var(--time-width);
    height: var(--time-height);
    border-radius: 20%;
    margin-right: 0.4rem;
    position: relative;
}

.time__next,
.time__current{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.time__current{
    transform: translateY(0);
    line-height: var(--time-height);
    transition: all 0s;
}

.time__next{
    transform: translateY(calc(-1 * var(--time-height)));
    transition: all 0s;
}

.time__current--anim{
    transform: translateY(var(--time-height));
    transition: transform 0.4s;
}

.time__next--anim{
    transform: translateY(0);
    line-height: var(--time-height);
    transition: transform 0.4s;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: none;
    text-align: center;
}

thead tr {
    background-image: linear-gradient(180deg, var(--primary-color) , var(--primary-color-very-dark));
    background-color: var(--primary-color-dark);
    border-bottom: 1px solid var(--nuetral-dark);
}

th {
    padding: 2rem 0.5rem;
    font-size: 0;
    color: var(--nuetral-light);
}

th i{
    font-size: 2rem;
}

td{
    padding: 2rem 0.5rem;
}



tbody tr:nth-child(odd) {
    background-image: linear-gradient(180deg, var(--primary-color-very-light) , var(--primary-color-light));
    background-color: var(--primary-color-light);
    border-bottom: 1px solid var(--nuetral-light);

}

tbody tr:nth-child(even) {
    border-bottom: 1px solid var(--nuetral-light);
    background-color: var(--primary-color-very-light);
}

.configs__app-v6 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.configs__info {
    --info-font-size: 2.1rem;
    margin-left: auto;
    position: relative;
    cursor: pointer;
    outline: none !important;
    -webkit-appearance: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}
.configs__app-name-wrapper{
    flex: 1 0 0;
}

.configs__app-name{
    color: #000;
    border-bottom: 1px solid var(--primary-color);
    padding-bottom: 0.2rem;
    transition: color 0.3s;
}

.configs__app-name-wrapper .configs__app-name{
    border-bottom: none;
}


.configs__app-icon{
    font-size: var(--info-font-size);
}

.tooltip,
.tooltip::after {
    --tooltip-scale: 0;
    opacity: 0;
    position: absolute;
    z-index: 10;
    transition: all 0.3s;
}

.tooltip {
    top: -1rem;
    left: 150%;
    width: max-content;
    max-width: calc(100vw / 1.5);
    transform: translate(-100%, -100%) scale(var(--tooltip-scale));
    transform-origin: bottom right;
    background-color: var(--nuetral-dark);
    color: white;
    padding: 1em;
    border-radius: 10px;
    font-size: 1.6rem;
    font-family: "iranyekan", "sans-serif";
    font-weight: 400;
    line-height: 1.5;
}

.tooltip::after {
    content: "";
    top: 100%;
    right: calc(var(--info-font-size) / 2);
    border: 1rem solid transparent;
    border-top-color: var(--nuetral-dark);
    transform: translateX(-0.08rem) scale(var(--tooltip-scale));
    transform-origin: top right;
}

.configs__app-radio{
    display: none;
}

.configs__app-radio:checked + .configs__app-icon + .tooltip,
.configs__app-radio:checked + .configs__app-icon + .tooltip:after{
    opacity: 1;
    --tooltip-scale: 1;
}


.tooltip__link{
    font-family: Arial, Helvetica, sans-serif;
    color: var(--primary-color-light);
}

.configs__os {
    font-size: 0;
}

.configs__os-container {
    font-size: 0;
    margin: -0.5rem
}

.configs__os i {
    font-size: 2.5rem;
    margin: 0.25rem;
}

@media only screen and (min-width: 37em ) {
    .configs__os i {
        font-size: 3.5rem;
        margin: 0.5rem;
    }
}
@media only screen and (min-width: 48em ) {

    .tooltip {
        max-width: calc(100vw / 2);
    }
}


@media only screen and (min-width: 62em ) {

    td, th {
        padding: 2.5rem;
    }


    th i{
        font-size: 2.7rem;
    }


    .tooltip {
        max-width: calc(100vw / 2.5);
    }

}


@media (hover:hover) and (pointer: fine) {
    .configs__app-name:hover{
        color: var(--primary-color-very-dark);
    }

    .configs__app-name-wrapper .configs__app-name:hover{
        color: #000;
    }
}